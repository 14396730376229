@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */




.dateSelector:focus {
  outline: none;
}

.userModelBody{
  overflow-y: auto;
  max-height: 297px;
}
.user-list {
    border: 1px solid #00000042 !important;
    cursor: pointer;
  }
  .user-list:hover {
    transform: scale(1.05, 1.05);
  }
.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 20px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: #417504;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #417504;
  }
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  /* Rounded sliders */
  
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
